import {
    PATHS,
    SCROLL_WATCH 
} from '../../constants';
import Load from 'storm-load';

export default () => {
    if(!document.querySelector(SCROLL_WATCH.SELECTOR.ARTICLE_NAV)) return;

    Load(`${PATHS.JS_ASYNC}/scroll-watch.min.js`)
        .then(() => {
            if(document.querySelector(SCROLL_WATCH.SELECTOR.ARTICLE_NAV)) {
                ScrollWatch.init(SCROLL_WATCH.SELECTOR.ARTICLE_NAV, {
                    offset: 10,
                    activeClassName: 'active',
                    callback: null
                });
            }
        });
}