import { PATHS, MODAL_GALLERY } from '../../constants';
import Load from 'storm-load';

export default () => {
    if(!document.querySelector(MODAL_GALLERY.SELECTOR.BASE)) return;

    Load(`${PATHS.JS_ASYNC}/modal-gallery.min.js`)
        .then(() => {
            document.querySelector(MODAL_GALLERY.SELECTOR.VIDEO) && ModalGallery.init(MODAL_GALLERY.SELECTOR.VIDEO);
        });
} 