import {
    STICKY_HEADER 
} from '../../constants';
import throttle from 'raf-throttle';

const watch = () => {
    if(window.pageYOffset > 0){
        if(!document.documentElement.classList.contains(STICKY_HEADER.CLASSNAME)) document.documentElement.classList.add(STICKY_HEADER.CLASSNAME);
    }
    else if(document.documentElement.classList.contains(STICKY_HEADER.CLASSNAME)) document.documentElement.classList.remove(STICKY_HEADER.CLASSNAME);
};

export default () => { window.addEventListener('scroll', throttle(watch, 16)); };
