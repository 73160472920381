import { PATHS, SIEMA } from './constants';
import Promise from 'promise-polyfill';
import Load from 'storm-load';
import Toggle from './require/toggle';
import ScrollWatch from './require/scroll-watch';
import StickyHeader from './require/sticky-header';
import ModalGallery from './require/modal-gallery';
import AsyncHelper from './require/async-helper';
import Fonts from './require/fonts';
import 'lazysizes';


const onInit = [
	Fonts,
	ModalGallery,
	Toggle,
	ScrollWatch,
	StickyHeader,
	AsyncHelper('tabs'),
	AsyncHelper('scroll-to'),
	AsyncHelper('scroll-spy'),
	AsyncHelper('scroll-points'),
	AsyncHelper('wall'),
	() => {
		if(!document.querySelector(SIEMA.CARDS.SELECTOR)) return;
		
		const siemaItems = document.querySelectorAll(SIEMA.CARDS.SELECTOR);

		Load(`${PATHS.JS_ASYNC}/siema.min.js`)
			.then(() => { 
				for(const item of siemaItems){
					const siema = new Siema({
						selector: item,
						draggable: false,
						perPage: {
							550: 2,
							1080: 3,
							1440: 4,
						},
						loop: true,
					});
					['prev', 'next'].forEach(type => {
						[].slice.call(document.querySelectorAll(SIEMA[type.toUpperCase()]))
							.forEach(btn => btn.addEventListener('click', () => siema[type]()));
					});

				}
			}); 
	},
	() => {
		if(!document.querySelector(SIEMA.SECRETS.SELECTOR)) return;

		const siemaItems = document.querySelectorAll(SIEMA.SECRETS.SELECTOR);

		Load(`${PATHS.JS_ASYNC}/siema.min.js`)
			.then(() => { 
				for(const item of siemaItems){
					const siema = new Siema({
						selector: item,
						draggable: false,
						perPage: 1,
						loop: false,
					});
					['prev', 'next'].forEach(type => {
						[].slice.call(document.querySelectorAll(SIEMA[type.toUpperCase()]))
							.forEach(btn => btn.addEventListener('click', () => siema[type]()));
					});
				}}
			); 
	},
	() => {
		if(!document.querySelector(SIEMA.SINGLE_IMAGE.SELECTOR)) return;

		const siemaItems = document.querySelectorAll(SIEMA.SINGLE_IMAGE.SELECTOR);


		Load(`${PATHS.JS_ASYNC}/siema.min.js`)
			.then(() => { 
				for(const item of siemaItems){
					const siema = new Siema({
						selector: item,
						draggable: false,
						perPage: 1,
						loop: true,
					});
					['prev', 'next'].forEach(type => {
						[].slice.call(document.querySelectorAll(SIEMA[type.toUpperCase()]))
							.forEach(btn => btn.addEventListener('click', () => siema[type]()));
					});
				}}
			); 
	}
];

{
	window.Promise = window.Promise ? window.Promise : Promise;
	document.documentElement.className =  document.documentElement.className.split('no-js').join('');

	window.lazySizesConfig = window.lazySizesConfig || {};
	window.lazySizesConfig.expand = 0;

	if(!Object.assign) {
		Load(`${PATHS.JS_ASYNC}/polyfills.min.js`)
			.then(() => onInit.map(fn => fn()));
	} else onInit.map(fn => fn()); 
}