const extractPathName = () => {
	let scripts = document.getElementsByTagName('script'),
		pathName = scripts[scripts.length-1].src;
	
	return pathName.substr(0, pathName.lastIndexOf('/'));
};

export const PATHS = {
	JS_ASYNC: `${extractPathName()}/async`
};

export const AI = {
	META: 'data-ai'
};

export const FONTS = {
	custom: {
		families: ['Gill Sans Nova W01', 'Gotham SSm A', 'Gotham SSm B'],
		urls: [
			'https://cloud.typography.com/762634/7527012/css/fonts.css',
		  	'//fast.fonts.net/cssapi/58b3ced2-d486-423b-8d0c-7945662c0766.css'
		]
	}
};

export const TOGGLES = {
	SELECTOR:{
		GLOBAL: '.js-toggle',
		LOCAL: '.js-toggle-local'
	},
	OPTIONS: {
		LOCAL: {
			local: true
		}
	}
};

export const SCROLL_TO = {
	SELECTOR: '.js-scroll-to'
}

export const SCROLL_POINTS = {
	SELECTOR: '.js-scroll-point',
	OPTIONS: {
		offset: '15%'
	}
}

export const SCROLL_SPY = {
	SELECTOR: '.js-scroll-spy'
}

export const SCROLL_WATCH = {
	SELECTOR: {
		GLOBAL: '.js-scroll-watch',
		ARTICLE_NAV: '.js-scroll-watch--article-nav'
	}
}

export const STICKY_HEADER = {
	CLASSNAME: 'is--stuck'
}

export const WALL = {
	SELECTOR: '.js-wall'
};

export const SIEMA = {
	PREV: '.js-siema-prev',
	NEXT: '.js-siema-next',
	CARDS: {
		SELECTOR: '.js-siema--cards'
	},
	SECRETS: {
		SELECTOR: '.js-siema--secrets'
	},
	SINGLE_IMAGE: {
		SELECTOR: '.js-siema--single-image'
	}
}

export const TABS = {
	SELECTOR: '.js-tabs'
}

export const MODAL_GALLERY = {
	SELECTOR: {
		BASE: '.js-modal-gallery',
		VIDEO: '.js-modal-gallery--video'
	}
};

